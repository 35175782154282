const InlineEditor = require('@ckeditor/ckeditor5-build-inline/build/ckeditor')

let gEditor;

document.addEventListener("tag_input_loaded", () => {
  if ( document.querySelector( '#value_div' ) != null ){
    InlineEditor
      .create( document.querySelector( '#value_div' ), {
          toolbar: [ 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
          placeholder: 'Info'
      }  )
      .then( editor => {
          console.log( editor );
          console.log(Array.from( editor.ui.componentFactory.names()));
          gEditor = editor;
          // editors.push(editor);
      })
      .catch( error => {
          console.log( error );
      });
    if (document.querySelector( '#templet_tag_form' ) != null){
      document.querySelector( '#templet_tag_form' ).addEventListener( 'submit', () => {
        $('#value').val(gEditor.getData())
      } );
    }
  }
});
