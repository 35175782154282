const InlineEditor = require('@ckeditor/ckeditor5-build-inline/build/ckeditor')

let gEditor;
let fEditor;

document.addEventListener("turbolinks:load", () => {
  if ( document.querySelector( '#email_text_info_text_div' ) != null ){
    InlineEditor
      .create( document.querySelector( '#email_text_info_text_div' ), {
          toolbar: [ 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
          placeholder: 'Info'
      }  )
      .then( editor => {
          console.log( editor );
          console.log(Array.from( editor.ui.componentFactory.names()));
          gEditor = editor;
          // editors.push(editor);
      })
      .catch( error => {
          console.log( error );
      });
      InlineEditor
        .create( document.querySelector( '#email_text_footer_text_div' ), {
            toolbar: [ 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
            placeholder: 'Voettekst'
        }  )
        .then( editor => {
            console.log( editor );
            console.log(Array.from( editor.ui.componentFactory.names()));
            fEditor = editor;
            // editors.push(editor);
        })
        .catch( error => {
            console.log( error );
        });
    if (document.querySelector( '#et_form' ) != null){
      document.querySelector( '#et_form' ).addEventListener( 'submit', () => {
        $('#email_text_info_text').val(gEditor.getData())
        $('#email_text_footer_text').val(fEditor.getData())
      } );
    }
  }
});
