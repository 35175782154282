// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery");
require("popper.js");
require('bootstrap');
require('moment');

global.Rails = Rails;

var $ = require('jquery');
global.$ = jQuery;

require("blueimp-file-upload");

// CSS
import 'scss/application'

// JS
import('js/site')
import "./inline_editor_email_texts"
import "./inline_editor_subscription_types"
import "./inline_editor_tag_input"
import "./calendar"


// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require('jquery-ui');
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/disable-selection')

require("chartkick")
require("chart.js")

const dataTables = [];

document.addEventListener("turbolinks:load", () => {
  $.getScript("/js/mdb.min.js", function(){
      console.log('MDB Loaded.');
  });

  $.getScript("/js/bootstrap-material-datetimepicker-bs4.js", function(){
     console.log('BS 4 datetimepicker loaded');
  });

  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 50
      }));
    });
  }

  activateFlags();
	hideAlerts();
  scaleItems();

});

document.addEventListener("turbolinks:before-cache", () => {
  $.getScript("/js/mdb.min.js", function(){
     console.log('MDB Loaded before cache.');
  });
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

$(window).bind('resize orientationchange', function() {
	scaleItems();
});

function scaleItems() {
  var window_height = $(window).height();

  if ( $('body').hasClass('dit_is_assen') ) {
    alert('Dit is Assen!')
    var content_height = window_height - 31; // window-height - footer-height
    content_height = content_height - 190;
    console.log(content_height);
    console.log('- content_height ------------------------');

    $('main').css('height', content_height+'px');
  } else {
    if ($('main').length) {
      var content_height = window_height - 31; // window-height - footer-height
      var main_height = $('main').height();
      if ( $('body').hasClass('guest') ) {
        content_height = content_height - 90;
      }
      if ( $('body').hasClass('mobile') && $('body').hasClass('devise') ) {
        content_height = content_height - 9;
        $('body').css('height', content_height+'px');
        $('main').css('height', content_height+'px');
      } else if ( $('body').hasClass('mobile') && $('body').hasClass('calculation_result') ) {
          content_height = content_height - 33;
          $('body').css('height', content_height+'px');
          $('main').css('height', content_height+'px');
      } else {
        if ( main_height < content_height ) {
          $('main').css('min-height', content_height+'px');
        }
      }
      if ( $('body').hasClass('administration') ) {
        var tab_height = $('.tab-content').height();

        $('main').css('height', (tab_height + 220) +'px');
      }
    }
  }

  var devHeight = 0;
  var min_height = $('div.content').height();

	if ($('div#development-div').length) {
    devHeight = $('#development-div').height() + 4;
	}

  if ( window_height >= min_height ) {
  	if ($('div.content').length) {
  		var content_height = window_height - devHeight;
      content_height = content_height - 144;
  		if ($('body').hasClass('devise')) {
  			content_height = content_height + 8
    	} else if ($('body').hasClass('tablet')) {
        var bottom_height = $('nav.tablet-bottom').height();
        content_height = content_height - bottom_height
        content_height = content_height + 58
    	} else if ($('body').hasClass('greenledwalls')) {
        content_height = content_height + 13
  		}

  		if ( !$('body').hasClass('mobile') ) {
        $('div.content').css('min-height', content_height+'px');
      }
  	}
  }
}

function hideAlerts() {

	if ($('div.alert').length) {
		setTimeout(function() {
			$( "div.alert" ).hide( 'fade', {}, 2000, null );
		}, 4000 );
	}

};

function activateFlags() {
  $('.flags')
    .click(function() {
      var languages = "<%= languages_array.join('||') %>".split('||');
      var locale = this.getAttribute('data-locale');
      var url = window.location.toString();
      var nw_url = ""

      for (var i=0, l=languages.length; i < l; i++) {
        var s = '?locale='+ languages[i];
        var m = '&locale='+ languages[i];
        if (url.indexOf(s) >= 0) {
          url = url.replace(s, "");
        } else if (url.indexOf(m) >= 0) {
          url = url.replace(m, "");
        }
      }

      if (url.toString().indexOf('?') >= 0) {
        nw_url = url + "&locale=" + locale
      } else {
        nw_url = url + "?locale=" + locale
      }
      window.location = nw_url;
  });

};

function activateDateTimePickers() {

	$('.date').datetimepicker({
	  icons: {
	      time: "fal fa-clock",
	      date: "far fa-calendar-alt",
	      up: "fal fa-arrow-alt-up",
	      down: "fal fa-arrow-alt-down",
	      previous: "fal fa-arrow-alt-left",
	      next: "fal fa-arrow-alt-right"
	   },
     calendarWeeks: true,
	   format: "DD-MM-YYYY"
	});

	$('.time').datetimepicker({
	  icons: {
	      time: "fal fa-clock",
	      date: "far fa-calendar-alt",
	      up: "fal fa-arrow-alt-up",
	      down: "fal fa-arrow-alt-down",
	      previous: "fal fa-arrow-alt-left",
	      next: "fal fa-arrow-alt-right"
	   },
	   format: "LT"
	});

}
// U3SKDqLAe89XFKKTAd1P
