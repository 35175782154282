require("bootstrap");

import { Calendar } from '@fullcalendar/core';
import nlLocale from '@fullcalendar/core/locales/nl';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { formatDate } from '@fullcalendar/core'
import Popper from 'popper.js/dist/umd/popper.js';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

window.Popper = Popper;

document.addEventListener("turbolinks:load", () => {

  var date = new Date();
  var d = date.getDate();
  var m = date.getMonth();
  var y = date.getFullYear();
  var hours_per_day = 0;
  var minutes_per_day = 0;
  var prev_day = "";
  var totHoursPerDay = {};
  var idsPerDay = {};

  var loc = "nl";
  var firstDay = 1;
  var calDate = moment();

  var calendarEl = document.getElementById('reservations_calendar');

  function arrayRemove(arr, value) {
    return arr.filter(function(ele){
      return ele != value;
    });
  }

  var calendar = new Calendar(calendarEl, {
    locale: nlLocale,
    initialView: 'timeGridWeek',
    plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, bootstrapPlugin ],
    themeSystem: 'bootstrap',
    headerToolbar: {
    	left: 'prev,next today',
      center: 'title',
      right: 'timeGridDay,timeGridWeek'
    },
    buttonText: {
      today: 'vandaag',
      month: 'maand',
      week: 'week',
      agendaWeek: 'week',
      day: 'dag'
    },
    firstDay: firstDay,
    allDaySlot: false,
    slotEventOverlap: false,
    dayHeaderFormat: {
      weekday: 'long',
      month: 'numeric',
      day: 'numeric',
      omitCommas: true
    },
    // groupByDateAndResource: true,
    // // now: calDate,
    // // defaultDate: startDate,
    nowIndicator: true,
    height: 'auto',
    titleFormat: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    // // dayNamesShort: dayNames,
    // // monthNames: monthNames,
    // // monthNamesShort: monthNamesShort,
    aspectRatio: 0.25,
    scrollTime: '00:00:00',
    // weekNumbers: false,
    editable: false,
    slotDuration: '01:00',
    slotLabelInterval: '01:00',
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
    // resourceAreaWidth: '25%',
    // resourceGroupField: 'kind',
    // resourceLabelText: ' ',
    // refetchResourcesOnNavigate: true,
    events: '/get_reservation_events',
    // resources: '/get_reservation_resources',
    eventDidMount: function (info) {
      var event = info.event.extendedProps;
      if ( event.title != '' ) {
        var element = info.el;
        tippy(element, {
          content: event.description,
          allowHTML: true
        });
      }
    }
  });

  if ( document.querySelector( '#reservations_calendar' ) != null ){
    console.log('before render calendar')
    $('#reservations_calendar').html('');
    calendar.render();
  }
});
